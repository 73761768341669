import { Flex, Spinner } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Suspense, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { envConfig } from 'config';
import { useAuth } from 'features/auth';
import { setCustomerInfoCookies } from 'features/auth/utils/setCustomerInfoCookies';
import { useFetchCurrentLazyQuery } from 'generated/graphql';
import { FETCH_CURRENT } from 'javascript/actions/types';
import { RootStateType } from 'types/store';
import { mixpanelIdentify, mixpanelPeopleSetOnce, mixpanelRegister } from 'utils/mixpanel/mixpanel';

import { CurentDataType, useUserPermissions } from './useUserPermissions';

export const AuthGuard: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { setUserPermissions } = useUserPermissions();
  const [fetchCurrent, { data: currentData, loading }] = useFetchCurrentLazyQuery();
  const [tenantSlug] = useSelector(
    (state: RootStateType) => [state?.current?.tenant?.slug],
    shallowEqual
  );

  useEffect(() => {
    if (!auth.isAuthenticated) {
      //enable login url in production
      if (envConfig.environment === 'production') {
        window.location.href = process.env.REACT_APP_REDIRECT_URL || '/';
      } else {
        navigate(`/auth/login?redirect=${location.pathname}`);
      }
    }
  });

  useEffect(() => {
    if (currentData) {
      if (!currentData?.current?.user) {
        Sentry.setUser({});
        return;
      }

      const {
        user: { email, name, phone },
        customer,
        settings,
      } = currentData.current;

      mixpanelPeopleSetOnce({
        $email: email,
        $name: name,
        userPhoneNumber: phone,
        tenantName: customer?.name ?? '',
        tenantId: customer?.id ?? '',
      });
      mixpanelIdentify(email);
      const mixpanelData = {
        tenantName: customer?.name,
        tenantId: customer?.id,
        tenantSlug: customer?.tenantSlug,
        username: name,
        userPhoneNumber: phone,
      };
      mixpanelRegister(mixpanelData);
      let timezone = '';

      if (settings) {
        timezone =
          currentData?.current?.settings.find((item) => item.name === 'timezone')?.value || '';
      }
      Sentry.setUser({
        email,
        name,
        timezone,
        tenantName: customer?.name,
        tenantId: customer?.id,
        tenantSlug: customer?.tenantSlug,
      });
      setCustomerInfoCookies(currentData, timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  useEffect(() => {
    if (!tenantSlug) {
      fetchCurrent();
    }
  }, [fetchCurrent, tenantSlug]);

  useEffect(() => {
    if (currentData && !loading) {
      dispatch({
        type: FETCH_CURRENT,
        payload: currentData.current,
      });
    }
  }, [currentData, dispatch, loading]);

  /**
   * User Permissions Setter for the whole app
   */
  useEffect(() => {
    if (!setUserPermissions) return;

    if (currentData?.current?.user?.id && !loading) {
      const data = currentData?.current as unknown as CurentDataType; // HACK

      setUserPermissions(data);
    }
  }, [currentData, dispatch, loading, setUserPermissions]);

  return (
    <Suspense
      fallback={
        <Flex justify="center" align="center" h="100vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      }
    >
      <Outlet />
    </Suspense>
  );
};
