export const permissionLabels = {
  read: 'read',
  write: 'write',
};

export const featurePermissionFlagsLabels = {
  // invoice
  allowUserCreateInvoiceFeature: 'allow_user_create_invoice',
  allowUserGenerateInvoiceFeature: 'allow_user_generate_invoice',
  allowUserRebillInvoiceFeature: 'allow_user_rebill_invoice',
  allowUserSyncInvoiceFeature: 'allow_user_sync_invoice',

  // order margin
  allowUserAdjustOrderMarginFeature: 'allow_user_adjust_order_margin',

  // order lineitem
  allowUserModifyOrderProductLineItemFeature: 'allow_user_modify_order_product_line_item',
  allowUserUpdateOrderBillingDetailsLineItemFeature:
    'allow_user_update_order_billing_details_line_item',

  // order driver and truck
  allowUserChangeOrderDriverFeature: 'allow_user_change_order_driver',
  allowUserChangeOrderTruckFeature: 'allow_user_change_order_truck',

  // order creation
  allowUserCreateDeliveryOrderFeature: 'allow_user_create_delivery_order',
  allowUserCreateExtractionOrderFeature: 'allow_user_create_extraction_order',
  allowUserCreateLoadOrderFeature: 'allow_user_create_load_order',
  allowUserCreateMovementOrderFeature: 'allow_user_create_movement_order',
  allowUserCreateRecurringOrderFeature: 'allow_user_create_recurring_order',
  allowUserCreateOrderFromTankMonitorPageFeature: 'allow_user_create_order_from_tank_monitor_page',

  // Move shift to accounting sync ready
  allowUserToMoveShiftToAccountingSyncReadyFeature:
    'allow_user_to_move_shift_to_accounting_sync_ready',
};

const initialState = {
  user: null,

  queryPermissions: [],
  featurePermissions: {
    // initially all to false
    // invoice
    [featurePermissionFlagsLabels.allowUserCreateInvoiceFeature]: false,
    [featurePermissionFlagsLabels.allowUserGenerateInvoiceFeature]: false,
    [featurePermissionFlagsLabels.allowUserRebillInvoiceFeature]: false,
    [featurePermissionFlagsLabels.allowUserSyncInvoiceFeature]: false,
    // order margin and line item
    [featurePermissionFlagsLabels.allowUserAdjustOrderMarginFeature]: false,
    [featurePermissionFlagsLabels.allowUserUpdateOrderBillingDetailsLineItemFeature]: false,
    [featurePermissionFlagsLabels.allowUserModifyOrderProductLineItemFeature]: false,
    // order driver and truck
    [featurePermissionFlagsLabels.allowUserChangeOrderDriverFeature]: false,
    [featurePermissionFlagsLabels.allowUserChangeOrderTruckFeature]: false,
    // order creation
    [featurePermissionFlagsLabels.allowUserCreateDeliveryOrderFeature]: false,
    [featurePermissionFlagsLabels.allowUserCreateExtractionOrderFeature]: false,
    [featurePermissionFlagsLabels.allowUserCreateLoadOrderFeature]: false,
    [featurePermissionFlagsLabels.allowUserCreateMovementOrderFeature]: false,
    [featurePermissionFlagsLabels.allowUserCreateRecurringOrderFeature]: false,
    [featurePermissionFlagsLabels.allowUserCreateOrderFromTankMonitorPageFeature]: false,
    // Move shift to accounting sync ready
    [featurePermissionFlagsLabels.allowUserToMoveShiftToAccountingSyncReadyFeature]: false,
  },
  /**
   * Loading States
   */
  loading: false,
  hasLoadedAllPermissions: false,
};

export default function permissionReducer(state = initialState, { type, payload }) {
  switch (type) {
    case 'SET_USER_QUERY_PERMISSIONS':
      return {
        ...state,
        queryPermissions: payload,
      };
    case 'SET_USER_FEATURE_PERMISSIONS':
      return {
        ...state,
        featurePermissions: {
          ...state.featurePermissions,
          ...payload,
        },
      };
    default:
      return state;
  }
}
