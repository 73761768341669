import { Alert } from './Alert';
import { Button } from './Button';
import { Input } from './Input';
import { Modal } from './Modal';
import { switchTheme } from './Switch';
import { theme as topNavbarTheme } from './TopNavBar';

export const components = {
  Alert,
  Button,
  Input,
  Textarea: Input,
  Modal,
  Switch: switchTheme,
  ...topNavbarTheme,
};
