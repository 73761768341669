/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import * as workers from '../workers';

export const DELETED_ORDERS = '@dashboard/DELETED_ORDERS';
export const SET_ORDER_ID_MODAL = '@dashboard/SET_ORDER_ID_MODAL';
export const SET_ASSIGN_DRIVER_MODAL = '@dashboard/SET_ASSIGN_DRIVER_MODAL';
export const SET_ORDER_VERIFY_MODAL = '@dashboard/SET_ORDER_VERIFY_MODAL';
export const SET_ORDER_ASSIGN_MODAL = '@dashboard/SET_ORDER_ASSIGN_MODAL';
export const SET_ORDER_UNASSIGN_MODAL = '@dashboard/SET_ORDER_UNASSIGN_MODAL';
export const SET_ORDER_DELETE_MODAL = '@dashboard/SET_ORDER_DELETE_MODAL';
export const ENABLE_EDIT_GRID = '@dashboard/ENABLE_EDIT_GRID';
export const SET_IS_ORDER_VERIFIED = '@dashboard/SET_IS_ORDER_VERIFIED';
export const FETCH_ORDER_GROUP = '@dashboard/FETCH_ORDER_GROUP';
export const FETCH_ORDER_GROUPS = '@dashboard/FETCH_ORDER_GROUPS';
export const FETCH_ORDER_GROUPS_PRICING = '@dashboard/FETCH_ORDER_GROUPS_PRICING';
export const FETCHING_ORDER_GROUPS = '@dashboard/FETCHING_ORDER_GROUPS';
export const RESET_ALL_FETCHING_ORDER_GROUPS = '@dashboard/RESET_ALL_FETCHING_ORDER_GROUPS';
export const UPDATE_ORDER_ON_ASSIGNMENT = '@dashboard/UPDATE_ORDER_ON_ASSIGNMENT';
export const SET_NEW_TRANSFER_ORDER_MODAL_STATE = '@dashboard/SET_NEW_TRANSFER_ORDER_MODAL_STATE';
export const SET_SCHEDULE_TRANSFER_ORDER_MODAL_STATE =
  '@dashboard/SET_SCHEDULE_TRANSFER_ORDER_MODAL_STATE';
export const FETCH_DRIVER_SHIFT_DATA = '@dashboard/FETCH_DRIVER_SHIFT_DATA';
export const FETCH_DRIVERS_SHIFT_DATA = '@dashboard/FETCH_DRIVERS_SHIFT_DATA';
export const FETCH_CC_DRIVERS_SHIFT_DATA_LOADING = '@dashboard/FETCH_CC_DRIVERS_SHIFT_DATA_LOADING';
export const FETCH_CC_DRIVERS_SHIFT_DATA = '@dashboard/FETCH_CC_DRIVERS_SHIFT_DATA';
export const SET_END_SHIFT_MODAL = '@dashboard/SET_END_SHIFT_MODAL';
export const FETCH_CUSTOMER_DATA = '@dashboard/FETCH_CUSTOMER_DATA';
export const SET_DRIVER_GROUPS = '@dashboard/SET_DRIVER_GROUPS';
export const SET_DRIVER_GROUPS_LOADING = '@dashboard/SET_DRIVER_GROUPS_LOADING';
export const UPDATE_PRIORITY = '@dashboard/UPDATE_PRIORITY';
export const SET_HUBS = '@dashboard/SET_HUBS';
export const SET_COMMON_CARRIERS_LOADING = '@dashboard/SET_COMMON_CARRIERS_LOADING';
export const SET_COMMON_CARRIERS = '@dashboard/SET_COMMON_CARRIERS';
export const SET_ORDER_GROUP_COUNT = '@dashboard/SET_ORDER_GROUP_COUNT';
export const RESET_ORDER_GROUP_COUNT = '@dashboard/RESET_ORDER_GROUP_COUNT';

export const FETCH_UPDATE_SHIFT_PLANNER = '@dashboard/FETCH_UPDATE_SHIFT_PLANNER';
export const FETCH_UPDATE_CC_PLANNER = '@dashboard/FETCH_UPDATE_CC_PLANNER';

export const SET_UPDATED_SHIFT_PLANNER_TASK = '@dashboard/SET_UPDATED_SHIFT_PLANNER_TASK';
export const PUSHER_ORDERWELL_UPDATE = '@dashboard/PUSHER_ORDERWELL_UPDATE';
export const PUSHER_DRIVER_SHIFT_STARTED = '@dashboard/PUSHER_DRIVER_SHIFT_STARTED';
export const PUSHER_DRIVER_INVENTORY_UPDATED = '@dashboard/PUSHER_DRIVER_INVENTORY_UPDATED';
export const PUSHER_DRIVER_SHIFT_ENDED = '@dashboard/PUSHER_DRIVER_SHIFT_ENDED';
export const SET_SENDING_EMAIL_TO_DRIVER = '@dashboard/SET_SENDING_EMAIL_TO_DRIVER';

export const updatePriority = (orderId, priority) => workers.updatePriority(orderId, priority);
export const enableEditGrid = (status) => workers.enableEditGrid(status);
export const setOrderIdModal = (orderId) => workers.setOrderIdModal(orderId);
export const setOrderVerifyModal = (orders) => workers.setOrderVerifyModal(orders);
export const setOrderAssignModal = (orders) => workers.setOrderAssignModal(orders);
export const setOrderUnassignModal = (orders) => workers.setOrderUnassignModal(orders);
export const setOrderDeleteModal = (orders) => workers.setOrderDeleteModal(orders);
export const setIsOrderVerified = (status) => workers.setIsOrderVerified(status);
export const setAssignDriverModal = (payload) => {
  const {
    orderGroupId = null,
    driverId = null,
    date = '',
    startTime = '',
    endTime = '',
  } = payload || {};

  return workers.setAssignDriverModal({
    orderGroupId,
    date,
    startTime,
    endTime,
    driverId,
  });
};
export const setEndShiftModal = (payload) => workers.setEndShiftModal(payload);

export const fetchUpdateShiftPlanner = (payload) => workers.fetchUpdateShiftPlanner(payload);
export const setUpdatedShiftPlannerTask = (payload) => workers.setUpdatedShiftPlannerTask(payload);

export const fetchCustomerData = (customerId) => workers.fetchCustomerData(customerId);
export const fetchOrderGroup = (orderGroupId) => workers.fetchOrderGroup(orderGroupId);
export const fetchOrderWellOrders = (orderGroupId) => workers.fetchOrderWellOrders(orderGroupId);
export const fetchOrderGroups = (filter, viewName) => workers.fetchOrderGroups(filter, viewName);
export const fetchDriversShiftData = (driverType) => workers.fetchDriversShiftData(driverType);
export const fetchCCDriversShiftData = () => workers.fetchCcDriversShiftData();
export const fetchDriverShiftData = (id) => workers.fetchDriverShiftData(id);
export const updateOrderOnAssignment = (orderData) => workers.updateOrderOnAssignment(orderData);
export const openNewTransferOrderModal = () => workers.setNewTransferOrderModalState(true);
export const closeNewTransferOrderModal = () => workers.setNewTransferOrderModalState(false);
export const openScheduleTransferOrderModal = () =>
  workers.setScheduleTransferOrderModalState(true);
export const closeScheduleTransferOrderModal = () =>
  workers.setScheduleTransferOrderModalState(false);
export const deleteOrders = (deliveryOrderIds, loadingOrderIds, transferOrderIds) =>
  workers.deleteOrders(deliveryOrderIds, loadingOrderIds, transferOrderIds);
export const unassignDriver = (orderGroupId) => workers.unassignDriver(orderGroupId);
export const assignTask = (orderGroupId, startTime, driverId) =>
  workers.assignTask(orderGroupId, startTime, driverId);
export const updateOrderScheduledAt = (orderGroupId, datetime) =>
  workers.updateOrderScheduledAt(orderGroupId, datetime);
export const fetchDriverGroups = () => workers.fetchDriverGroups();
export const deleteOrderFromLocalForDashboard = (id) =>
  workers.deleteOrderFromLocalForDashboard(id);
export const fetchHubs = (payload) => workers.fetchHubs(payload);
export const pusherOrderWellUpdate = (payload) => workers.pusherOrderWellUpdate(payload);
export const pusherDriverShiftStarted = (payload) => workers.pusherDriverShiftStarted(payload);
export const pusherDriverInventoryUpdate = (payload) =>
  workers.pusherDriverInventoryUpdate(payload);
export const pusherDriverShiftEnded = (payload) => workers.pusherDriverShiftEnded(payload);
export const fetchCommonCarriers = (payload) => workers.fetchCommonCarriers(payload);
export const sendBulkOrderEmailToDriver = (orderGroupIds) =>
  workers.sendBulkOrderEmailToDriver(orderGroupIds);
