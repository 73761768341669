import styled from '@emotion/styled';

export const DateRangeWithTodayWrapper = styled.div`
  display: flex;
  align-items: center;

  .date-range-selector {
    display: flex;
    align-items: center;
    color: #fff;
    border: 1px solid #7c7c7c;
    border-radius: 5px;
    overflow: hidden;
    height: 40px;
    margin-left: 10px;

    .date-range-button.previous-btn,
    .date-range-button.next-btn {
      border-radius: 0;
      border: none;
      background: transparent;
      margin: 0;
      padding: 0;
      width: 33px;
      color: #ccc;
      height: 100%;

      &:hover {
        background: #7c7c7c;
        color: #222;
      }

      &.disabled {
        pointer-events: none;
      }
    }

    .date-container {
      width: 205px;
      border: 1px solid #7c7c7c;
      border-top: none;
      border-bottom: none;
      display: flex;
      height: 38px;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      cursor: pointer !important;
    }
  }

  .date-range-button.btn-set-today {
    margin-left: 10px;
    border-radius: 5px;
    border-width: 1px;
    height: 40px;
    padding: 0 15px;
    background: #3751fe;
    margin-left: 5px;
    margin-bottom: 0;
    border: none;
    color: #ffffff;

    &:hover {
      background: #3751fe;
    }

    &.active {
      background: #3751fe;
    }
  }
`;

export const TopBarStyles = {
  SpinnerStyles: {
    speed: '0.65s',
    emptyColor: 'transparent',
    color: 'orange.100',

    thickness: '2px',
    marginLeft: '10px',
  },
};
