import { useDispatch } from 'react-redux';

import { Current } from 'generated/graphql';
import {
  featurePermissionFlagsLabels,
  permissionLabels,
} from 'javascript/reducers/permissionReducer';
/**
 * @returns setUserPermissions
 */

export interface CurentDataType extends Current {
  userFeatureFlags: Array<string>;
}

export const useUserPermissions = () => {
  const dispatch = useDispatch();

  const setUserQueryPermissions = (currentData: CurentDataType) => {
    const userFlags = currentData?.userFeatureFlags;
    const user = currentData?.user;

    const queryPermissions = [permissionLabels.read]; // user is by default

    // Refactor this later

    // if user is not read only, add write permission by default
    // work on this logic later
    if (!userFlags?.includes('read_only_user')) {
      queryPermissions.push(permissionLabels.write);
    }
    // if user is super admin, add write permission by default
    if (user?.isSuperAdmin && !queryPermissions.includes(permissionLabels.write)) {
      queryPermissions.push(permissionLabels.write);
    }

    dispatch({
      type: 'SET_USER_QUERY_PERMISSIONS',
      payload: queryPermissions,
    });
  };

  const setUserFeaturePermissions = (current: CurentDataType) => {
    const userFlags = current?.userFeatureFlags;

    /**
     * Handle conditions for feature permissions
     *  1. case of common Carrier
     * ----- if user is a common carrier user then all flags to true
     *  isCarrier flag has to be true
     *  2. case of customer portal
     * ----- if user is a customer portal user then all flags to true
     *  tenant is null
     */

    const isCommonCarrierUser = current?.isCarrierOnly;
    const isCustomerPortalUser = current?.tenant === null;

    const overrideFlags = isCommonCarrierUser || isCustomerPortalUser;

    const featurePermissions: Record<string, boolean> = {};

    Object.values(featurePermissionFlagsLabels)?.forEach((flag) => {
      featurePermissions[flag] = overrideFlags || userFlags.includes(flag);
    });

    dispatch({
      type: 'SET_USER_FEATURE_PERMISSIONS',
      payload: featurePermissions,
    });
  };

  const setUserPermissions = (current: Current) => {
    const currentData = current as CurentDataType;

    // set user query permissions
    setUserQueryPermissions(currentData);

    // set user feature permissions
    setUserFeaturePermissions(currentData);
  };

  return { setUserPermissions };
};
