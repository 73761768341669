import Smartlook from 'smartlook-client';

import { ToastContainer } from 'lib/toast';

import './App.css';
import { envConfig } from './config';
import { AppProvider } from './providers/app';
import { AppRoutes } from './routes';

import 'react-datepicker/dist/react-datepicker.css';
import '@assistant-ui/react/styles/index.css';
import '@assistant-ui/react/styles/modal.css';

Smartlook.init(envConfig.smartlookKey);

export function App() {
  return (
    <AppProvider>
      <AppRoutes />
      <ToastContainer />
    </AppProvider>
  );
}
