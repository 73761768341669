import { Colors } from '@chakra-ui/react';

export const colors: Colors = {
  white: '#fff',
  black: {
    950: '#000',
    930: '#121417',
    910: '#181a1d',
    905: '#222',
    900: '#292929',
  },
  gray: {
    1000: '#121417',
    950: '#1a1b1e',
    900: '#1c1c1c',
    890: '#14181b',
    880: '#1c1c15',
    885: '#16171A',
    875: '#202226',
    870: '#002814',
    860: '#0b1033',
    850: '#1f2226',
    830: '#1e2023',
    800: '#1d1f23',
    770: '#21282e',
    780: '#20262b',
    775: '#232529',
    750: '#24282e',
    725: '#262c31',
    700: '#25282d',
    675: '#303339',
    650: '#2f333a',
    600: '#272f36',
    550: '#292e36',
    500: '#2f3239',
    476: '#2D3645',
    475: '#2d3436',
    470: '#2b343a', // detail modal (header, notes widget, table body)
    465: '#2A2C31',
    460: '#3b3f47',
    450: '#363a42',
    440: '#434549',
    430: '#45464b',
    420: '#444',
    400: '#2B3037',
    380: '#3c3f43',
    375: '#31363E',
    370: '#3D4248', // asset order history
    350: '#384047', // detail modal (body)
    325: '#303338',
    320: '#232428',
    310: '#444e56',
    300: '#6a6f76',
    275: '#B5B5B5',
    250: '#7E8892', // shift planner
    255: '#767684',
    225: '#444950',
    215: '#7c7c7c',
    210: '#888',
    200: '#8c8f94',
    150: '#96a2ae',
    125: '#595F65',
    120: '#666',
    115: '#999',
    111: '#A6A7AB',
    100: '#999999',
    90: '#b2a9a9',
    85: '#95999D',
    75: '#b2a9a9', //duplicate
    70: '#676a71',
    65: '#cccccc',
    55: '#e3e2e1',
    53: '#e9ebf0',
    50: '#f5f5f5',
    30: '#aaa',
    20: '#ddd',
    10: '#d5d6d7',
    5: '#acaeb0', // edit icon colors
  },
  orange: {
    50: '#ffc7b3',
    60: '#e05443',
    70: '#c53424',
    80: '#b12a1d',
    100: '#ff4200',
    200: '#f9a385',
    300: '#f47433',
    350: '#F15A24',
    400: '#f15c27',
    450: '#f0642d', // detail modal (comment), shift planner
    500: '#d8420e',
    550: '#d65a31',
    600: '#a9330a',
    700: '#792406',
    800: '#4b1400',
    850: '#331d06',
    890: '#330000',
    900: '#1f0300',
    925: '#f6b26b',
    950: '#FF931E',
    980: '#fa880c',
    990: '#f8901f',
    1000: '#FA6400',
  },
  brown: {
    50: '#8b8b69',
    700: '#331d06',
    800: '#312e00',
  },
  purple: {
    50: '#e4e7ff',
    75: '#D619E1',
    100: '#b4b9ff',
    150: '#9b79f1',
    200: '#848afb',
    300: '#4d55f8',
    350: '#3751ff',
    380: '#1d39f5',
    400: '#242cf5',
    500: '#0d14dc',
    600: '#070eac',
    700: '#030a7c',
    800: '#00054d',
    900: '#00011f',
  },
  red: {
    50: '#ffe4e9',
    100: '#fce6e2',
    200: '#f7879a',
    250: '#ba4f4f',
    300: '#f14366',
    400: '#ee2b44',
    450: '#e24041',
    475: '#d10229',
    500: '#cc0200',
    600: '#a60c0c',
    700: '#780d07',
    800: '#490901',
    900: '#1e0800',
  },
  green: {
    50: '#dfffe7',
    100: '#b7f7ca',
    200: '#8cf1ac',
    300: '#61eb91',
    400: '#37e578',
    500: '#1fcc65',
    600: '#13964e',
    650: '#00c765',
    675: '#2c360c',
    680: '#02a152',
    700: '#097133',
    750: '#00B752',
    800: '#014518',
    900: '#001902',
    1000: '#00B300',
  },
  blue: {
    50: '#e8ecfc',
    100: '#d0d9f9',
    150: '#cbd5e0',
    175: '#a0aec0',
    200: '#a1b3f2',
    300: '#4366e5',
    350: '#3182ce',
    400: '#2b53e2',
    425: '#2026df',
    450: '#2199e8',
    455: '#3657f5',
    475: '#3751fe',
    500: '#1440df',
    600: '#1033b2',
    700: '#0c2686',
    800: '#081a59',
    900: '#040d2d',
    925: '#0c2187',
    930: '#5391e9',
    950: '#3182ce',
    960: '#5c71ff',
    1000: '#5BB0FF',
  },
  lightblue: {
    400: '#59d0ff',
  },
  yellow: {
    50: '#f5e702',
    75: '#e4e40c',
    100: '#ddd328',
    150: '#C3F81F',
    200: '#ccc002',
    300: '#F6E05E',
    350: '#ebb708',
  },
  inConsistent: {
    500: '#8237fe',
  },
  inventory: {
    500: '#1E90FF',
  },
  inFlushing: {
    500: '#E81C1C',
  },
  loading: {
    500: '#F0AF18',
  },
};
