import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variants: Record<string, SystemStyleFunction> = {
  alertWarningLightYellow() {
    return {
      container: {
        bg: 'orange.925',
        color: 'black',
      },
    };
  },
};

export const Alert = {
  variants,
};
