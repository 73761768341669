/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import {
  DELETED_ORDERS,
  ENABLE_EDIT_GRID,
  FETCH_ORDER_GROUP,
  FETCH_ORDER_GROUPS_PRICING,
  FETCH_ORDER_GROUPS,
  FETCHING_ORDER_GROUPS,
  RESET_ALL_FETCHING_ORDER_GROUPS,
  SET_IS_ORDER_VERIFIED,
  SET_ORDER_ID_MODAL,
  SET_ASSIGN_DRIVER_MODAL,
  SET_ORDER_VERIFY_MODAL,
  SET_ORDER_ASSIGN_MODAL,
  SET_ORDER_UNASSIGN_MODAL,
  SET_ORDER_DELETE_MODAL,
  UPDATE_ORDER_ON_ASSIGNMENT,
  SET_NEW_TRANSFER_ORDER_MODAL_STATE,
  SET_SCHEDULE_TRANSFER_ORDER_MODAL_STATE,
  FETCH_DRIVERS_SHIFT_DATA,
  FETCH_CC_DRIVERS_SHIFT_DATA,
  FETCH_CC_DRIVERS_SHIFT_DATA_LOADING,
  FETCH_DRIVER_SHIFT_DATA,
  SET_END_SHIFT_MODAL,
  FETCH_UPDATE_SHIFT_PLANNER,
  FETCH_CUSTOMER_DATA,
  SET_DRIVER_GROUPS_LOADING,
  SET_DRIVER_GROUPS,
  SET_UPDATED_SHIFT_PLANNER_TASK,
  SET_HUBS,
  RESET_ORDER_GROUP_COUNT,
  SET_ORDER_GROUP_COUNT,
  PUSHER_ORDERWELL_UPDATE,
  PUSHER_DRIVER_SHIFT_STARTED,
  PUSHER_DRIVER_INVENTORY_UPDATED,
  PUSHER_DRIVER_SHIFT_ENDED,
  SET_COMMON_CARRIERS_LOADING,
  SET_COMMON_CARRIERS,
  SET_SENDING_EMAIL_TO_DRIVER,
} from '../actions';
import { getProducts } from '../components/NewShiftPlanner/components/LaneGroup/LaneGroup.service';
import { pusherDriverUpdate } from '../helper';

export const initialState = {
  canEdit: true,
  isOrderVerified: false,
  isNewTransferOrderModalOpen: false,
  orderIdModal: null,
  assignDriverModal: null,
  endShiftModal: null,
  verifyOrderModal: [],
  assignOrderModal: [],
  unassignOrderModal: [],
  deleteOrderModal: [],
  orderGroups: {},
  driversShiftData: [],
  ccDriversShiftDataLoading: false,
  ccDriversShiftData: [],
  driverGroupsLoading: false,
  driverGroups: [],
  updateShiftPlannerToggle: false,
  updatedShiftPlannerTaskId: 0,
  customerData: null,
  fetching: {
    orderWell: false,
    orderReview: false,
    shiftPlanner: false,
    commonCarrierPlanner: false,
  }, // to show overlay message while fetching data
  orderGroupCount: { fetched: 0, total: 0, hasFetchingErrors: false },
  hubData: [],
  commonCarrierDataLoading: false,
  commonCarrierData: [],
  sendingEmailToDriver: false,
};

export default function dashboardReducers(state = initialState, action) {
  switch (action.type) {
    case ENABLE_EDIT_GRID:
      return {
        ...state,
        canEdit: action.payload,
      };
    case SET_ORDER_ID_MODAL:
      return {
        ...state,
        orderIdModal: action.payload,
      };
    case SET_ASSIGN_DRIVER_MODAL:
      return {
        ...state,
        assignDriverModal: action.payload,
      };
    case SET_ORDER_VERIFY_MODAL:
      return {
        ...state,
        verifyOrderModal: action.payload,
      };
    case SET_ORDER_ASSIGN_MODAL:
      return {
        ...state,
        assignOrderModal: action.payload,
      };
    case SET_ORDER_UNASSIGN_MODAL:
      return {
        ...state,
        unassignOrderModal: action.payload,
      };
    case SET_ORDER_DELETE_MODAL:
      return {
        ...state,
        deleteOrderModal: action.payload,
      };
    case SET_IS_ORDER_VERIFIED:
      return {
        ...state,
        isOrderVerified: action.payload,
      };
    case FETCH_ORDER_GROUP:
      return {
        ...state,
        orderGroups: { ...state.orderGroups, ...action.payload },
      };
    case FETCH_ORDER_GROUPS: {
      let tempClonedOrderGroups = structuredClone({ ...state.orderGroups, ...action.payload });
      Object.keys(tempClonedOrderGroups)?.forEach((indvOrderGroupKey) => {
        tempClonedOrderGroups = {
          ...tempClonedOrderGroups,
          [indvOrderGroupKey]: {
            ...tempClonedOrderGroups[indvOrderGroupKey],
            feePricingLoading: true,
          },
        };
      });
      return {
        ...state,
        orderGroups: tempClonedOrderGroups,
      };
    }
    case FETCH_ORDER_GROUPS_PRICING: {
      let tempOrderGroups = structuredClone(state.orderGroups);
      const actionPayload = action.payload;
      Object.keys(state.orderGroups)?.forEach((indvOrderGroupKey) => {
        tempOrderGroups = {
          ...tempOrderGroups,
          [indvOrderGroupKey]: {
            ...tempOrderGroups[indvOrderGroupKey],
            ...actionPayload[indvOrderGroupKey],
            feePricingLoading: false,
          },
        };
      });
      return {
        ...state,
        orderGroups: tempOrderGroups,
      };
    }
    case FETCH_DRIVERS_SHIFT_DATA:
      return {
        ...state,
        driversShiftData: [...action.payload],
      };
    case FETCH_CC_DRIVERS_SHIFT_DATA_LOADING:
      return {
        ...state,
        ccDriversShiftDataLoading: action.payload,
      };
    case FETCH_CC_DRIVERS_SHIFT_DATA:
      return {
        ...state,
        ccDriversShiftData: [...action.payload],
      };
    case FETCH_DRIVER_SHIFT_DATA:
      return {
        ...state,
        driversShiftData: [
          ...state.driversShiftData.filter(({ id }) => id !== action.payload.id),
          action.payload,
        ],
      };
    case RESET_ORDER_GROUP_COUNT:
      return {
        ...state,
        orderGroupCount: { fetched: 0, total: 0, hasFetchingErrors: false },
      };
    case SET_ORDER_GROUP_COUNT:
      return {
        ...state,
        orderGroupCount: {
          fetched: action.payload.fetched,
          total: action.payload.total,
          hasFetchingErrors: action.payload.hasFetchingErrors,
        },
      };
    case RESET_ALL_FETCHING_ORDER_GROUPS:
      return {
        ...state,
        fetching: {
          orderWell: false,
          orderReview: false,
          shiftPlanner: false,
          commonCarrierPlanner: false,
        },
      };
    case FETCHING_ORDER_GROUPS:
      return {
        ...state,
        fetching: { ...state.fetching, ...action.payload },
      };
    case UPDATE_ORDER_ON_ASSIGNMENT:
      return {
        ...state,
        orderGroups: action.payload,
      };
    case SET_NEW_TRANSFER_ORDER_MODAL_STATE:
      return {
        ...state,
        isNewTransferOrderModalOpen: action.payload,
      };
    case SET_SCHEDULE_TRANSFER_ORDER_MODAL_STATE:
      return {
        ...state,
        isScheduleTransferOrderModalOpen: action.payload,
      };
    case DELETED_ORDERS: {
      const _tempListOrderGroups = { ...state.orderGroups };
      delete _tempListOrderGroups[action.payload];
      return {
        ...state,
        orderGroups: { ..._tempListOrderGroups },
      };
    }
    case SET_END_SHIFT_MODAL:
      return {
        ...state,
        endShiftModal: action.payload,
      };
    case FETCH_UPDATE_SHIFT_PLANNER:
      return {
        ...state,
        updateShiftPlannerToggle: action.payload,
      };
    case SET_UPDATED_SHIFT_PLANNER_TASK:
      return {
        ...state,
        updatedShiftPlannerTaskId: action.payload,
      };
    case FETCH_CUSTOMER_DATA:
      return {
        ...state,
        customerData: action.payload,
      };
    case SET_DRIVER_GROUPS_LOADING:
      return {
        ...state,
        driverGroupsLoading: action.payload,
      };
    case SET_DRIVER_GROUPS:
      return {
        ...state,
        driverGroups: action.payload,
      };
    case SET_HUBS:
      return {
        ...state,
        hubData: action.payload,
      };
    case SET_COMMON_CARRIERS_LOADING:
      return {
        ...state,
        commonCarrierDataLoading: action.payload,
      };
    case SET_COMMON_CARRIERS:
      return {
        ...state,
        commonCarrierData: action.payload,
      };
    case PUSHER_ORDERWELL_UPDATE: {
      const { id, ...restPayload } = action.payload;

      /**
       * The pusher has a object with id key
       * Find the id in the orderGroups object by spliting its keys with _ eg.123_orderGroup
       * If the key is found then update that object in the order group else return default
       */
      const tempOrderGrpKey = Object.keys(state.orderGroups).find((indvOrderGrpKey) => {
        const splitOrderGrpKey = indvOrderGrpKey.split('_');
        return Number(id) === Number(splitOrderGrpKey[0]);
      });

      const tempOrderGroups = tempOrderGrpKey
        ? {
            ...JSON.parse(JSON.stringify(state.orderGroups)),
            [tempOrderGrpKey]: { ...state.orderGroups[tempOrderGrpKey], ...restPayload },
          }
        : state.orderGroups;

      return {
        ...state,
        orderGroups: tempOrderGroups,
      };
    }

    case PUSHER_DRIVER_SHIFT_STARTED: {
      const { id, activeShift } = action.payload;

      /**
       * The pusher data contains the driver id , activeShift
       * Update the value of the driver which matches the pusher driver id
       */

      const tempDriverShiftData = [...state.driversShiftData];
      const driverToUpdateIndex = tempDriverShiftData.findIndex(
        (indvDriver) => Number(indvDriver.id) === Number(id)
      );

      tempDriverShiftData[driverToUpdateIndex] = {
        ...tempDriverShiftData[driverToUpdateIndex],
        activeShift: {
          ...tempDriverShiftData[driverToUpdateIndex]?.activeShift,
          ...activeShift,
        },
      };

      return {
        ...state,
        driversShiftData: tempDriverShiftData,
      };
    }

    case PUSHER_DRIVER_INVENTORY_UPDATED: {
      let tempDriversShiftData = pusherDriverUpdate(action.payload.driver, state.driversShiftData);

      tempDriversShiftData = tempDriversShiftData?.map((indvDriverShift) => ({
        ...indvDriverShift,
        products: getProducts(indvDriverShift),
      }));

      return {
        ...state,
        driversShiftData: tempDriversShiftData,
      };
    }

    case PUSHER_DRIVER_SHIFT_ENDED: {
      /**
       * The pusher data contains the driver id
       * Update the active shift to null of the driver which matches the pusher driver id
       */

      const tempDriverShiftData = [...state.driversShiftData];
      const driverToUpdateIndex = tempDriverShiftData.findIndex(
        (indvDriver) => Number(indvDriver.id) === Number(action.payload)
      );

      tempDriverShiftData[driverToUpdateIndex] = {
        ...tempDriverShiftData[driverToUpdateIndex],
        activeShift: null,
        products: null,
      };

      return {
        ...state,
        driversShiftData: tempDriverShiftData,
      };
    }

    case SET_SENDING_EMAIL_TO_DRIVER:
      return {
        ...state,
        sendingEmailToDriver: action.payload,
      };

    default:
      return state;
  }
}
