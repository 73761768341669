import { Box, Flex, Select, Spinner, Text, useControllableState } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useEffect, useMemo } from 'react';

import { Pagination } from 'components/Pagination';
import { PandaGrid } from 'components/PandaGrid';

type GridProps = React.ComponentProps<typeof PandaGrid>;

type TableViewProps = GridProps & {
  fitWidth?: boolean;
  itemCount?: number;
  pageCount?: number;
  currentPage?: number;
  pageSize?: number;
  showItemCountOnly?: boolean;
  dataLoadingForTable?: boolean; // optional prop to show loading spinner
  onPaginationChange?: (attr: 'currentPage' | 'pageSize' | 'pageCount', value: number) => void;
};

const pageSizes = [10, 20, 30, 50, 100, 200, 400];

const TableViewInner: React.ForwardRefRenderFunction<AgGridReact, TableViewProps> = (
  props,
  ref
) => {
  const { defaultColDef, fitWidth = true, boxProps, ...gridProps } = props;

  const defaultColumnDef: GridProps['defaultColDef'] = useMemo(
    () => ({
      resizable: true,
      ...defaultColDef,
    }),
    [defaultColDef]
  );

  const handlePaginationChange =
    (attr: 'currentPage' | 'pageSize' | 'pageCount') => (value: number) => {
      props.onPaginationChange?.(attr, value);
    };

  const [pageSize, setPageSize] = useControllableState({
    defaultValue: pageSizes[0],
    value: props.pageSize,
    onChange: (value) => {
      handlePaginationChange('pageSize')(value);
      setCurrentPage(1);
    },
  });

  const [currentPage, setCurrentPage] = useControllableState({
    defaultValue: 1,
    value: props.currentPage,
    onChange: handlePaginationChange('currentPage'),
  });

  const [pageCount, setPageCount] = useControllableState({
    defaultValue: 0,
    value: props.pageCount,
    onChange: handlePaginationChange('pageCount'),
  });

  const [itemCount, setItemCount] = useControllableState({
    defaultValue: 0,
    value: props.itemCount,
  });

  useEffect(() => {
    if (props.pageCount && !props.itemCount) {
      setItemCount(props.pageCount * pageSize);
    } else if (props.itemCount && !props.pageCount) {
      setPageCount(Math.ceil((props.itemCount ?? 0) / pageSize));
    }
  }, [props.itemCount, pageSize, props.pageCount, setPageCount, setItemCount]);

  return (
    <Flex flexDirection={'column'} {...boxProps}>
      <PandaGrid
        ref={ref}
        {...gridProps}
        boxProps={{ h: 'full' }}
        defaultColDef={defaultColumnDef}
        onGridReady={(event) => {
          if (fitWidth) {
            event.api.sizeColumnsToFit();
          }
          gridProps.onGridReady?.(event);
        }}
        onSortChanged={(event) => {
          // This sets the current page to 1 while sorting from table header.
          // This behaviour is not present in main app so following code has been commented.
          // handlePaginationChange('currentPage')(1);
          gridProps.onSortChanged?.(event);
        }}
      />
      {/*
       * If showItemCountOnly is true, then only show the item count.
       * If showItemCountOnly is false, then show the pagination and item count.
       */}
      {props.showItemCountOnly && (
        <Box
          as="span"
          width={'max-content'}
          padding={'5px 20px'}
          border={'1px solid gray'}
          borderRadius={'5px'}
          fontSize={'sm'}
          margin={'10px 0px'}
          textAlign={'center'}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          {
            props.dataLoadingForTable && (
              <Spinner
                sx={{
                  speed: '0.65s',
                  emptyColor: 'transparent',
                  color: 'orange.100',
                  thickness: '2px',
                }}
                size={'sm'}
              />
            ) // This is a custom spinner component
          }
          <Text>{props?.itemCount} rows</Text>
        </Box>
      )}

      {!!(props.pageCount || props.itemCount) && !props.showItemCountOnly && (
        <Flex
          flex="1 1 fit-content"
          flexDirection={'row-reverse'}
          justifyContent={'space-between'}
          pt={'2'}
        >
          <Flex alignItems={'center'}>
            <Select
              size="sm"
              value={pageSize}
              onChange={(event) => setPageSize(Number(event.target.value))}
            >
              {pageSizes.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} rows
                </option>
              ))}
            </Select>
            <Text fontSize={'sm'} ml={2} minW={'fit-content'}>
              {[
                'Results:',
                currentPage * pageSize - pageSize + 1,
                '-',
                Math.min(currentPage * pageSize, itemCount ?? 0),
                'of',
                itemCount ?? 0,
              ].join(' ')}
            </Text>
          </Flex>
          {pageCount > 1 && itemCount > 0 && (
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onPageChange={setCurrentPage}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

TableViewInner.displayName = 'TableView';

export const TableView = forwardRef(TableViewInner);

export type TableView = typeof TableView;
