/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export default `
  id
  startOnTimestamp
  endOnTimestamp
  state
  errors
  enableClearingAccountingData
  bolErrors{
    orderGroupId
    volume
  }
  bolRetains{
    volume
    product{
      id
      shortName
    }
  }
  worker {
    id
    name
    status
    hubs {
      name
    }
  }
  shiftDeliveryUnitsAssets {
    id
    deliveryAsset {
      id
      licensePlateNumber
      name
      category
    }
  }
  shiftCompartmentInstances{
    id
    shiftStartInventory{
      product{
        id
        shortName
      }
      volume
    }
  }
  orderGroups {
    syncErrors
    hub {
      id
      name
    }
    carrierNumber
    poNumber
    lineOfService {
      id
      name
      shortName
    }
    loadingOrder {
      loadingReceipts {
        receiptNumber
        loadingAccount {
          carrierCustomerCode
        }
      }
    }
    deliveryOrders {
      id
      customerBranch {
        id
        name
        site {
          id
          address
        }
      }
    }
    loadingOrders {
      id
      terminal {
        id
        name
      }
    }
    extractionOrders {
      id
      customerBranch {
        id
        name
        site {
          id
          address
        }
      }
    }
  }
`;
